import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import borderUrl from '../../assets/images/disco_embed_border.svg';
import buttonUrl from '../../assets/images/photo_border.svg';
import { ReactComponent as Tape } from '../../assets/images/form_tape.svg';

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 3.5rem 0;

  @media (max-width: ${props => props.theme.breakMedium}) {
    margin-top: calc(2rem + 50px);
  }
`;

const StyledTape = styled(Tape)`
  position: absolute;
  top: -1.5rem;
  left: 50%;
  width: 10rem;
  transform: translateX(-50%);
  stroke: ${props => props.theme.black};
  stroke-width: 1.5;

  @media (max-width: ${props => props.theme.breakMedium}) {
    top: -1rem;
    width: 7rem;
    stroke-width: 2;
  }
`;

const StyledBorder = styled.div`
  ${props => props.theme.borderMask(borderUrl)};
`;

const StyledForm = styled.form`
  display: grid;
  grid-template:
    'content content'
    'date venue'
    'credit credit-link'
    'email others'
    'terms terms'
    'submit submit'
    / 1fr 1fr;
  gap: 1.5rem 3rem;
  width: 90vw;
  max-width: ${props => props.theme.breakLarge};
  padding: 4rem 3rem 3rem;
  background: ${props => props.theme.white};
  ${props => props.theme.mask(borderUrl)};

  @media (max-width: ${props => props.theme.breakMedium}) {
    display: block;
    padding: 3rem 2rem 2rem;
    text-align: center;
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    display: block;
    padding: 2rem 1rem;
  }
`;

const StyledUpload = styled.input`
  grid-area: file;
  opacity: 0;
  width: 0.1px !important;
  height: 0.1px !important;
  position: absolute;
`;

const StyledUploadText = styled.span`
  grid-area: text;
  padding-top: 0.6rem;
  font-size: 0.875rem;
  ${props => props.theme.customTextStyle};

  @media (max-width: ${props => props.theme.breakMedium}) {
    display: inline-block;
    padding-top: 1rem;
  }
`;

const StyledUploadLabel = styled.label`
  display: inline-block;
  border: 2px solid black;
  color: ${props => props.theme.white};
  font-size: 0.875rem;
  ${props => props.theme.customTextStyle};
  ${props => props.theme.borderMask(buttonUrl)};
  padding: 0.5rem 2.5rem 0.3rem;
  cursor: pointer;

  span {
    font-size: 0.625rem;
    white-space: nowrap;
  }

  @media (max-width: ${props => props.theme.breakLarge}) {
    padding: 0.5rem 1rem 0.3rem;
  }

  @media (max-width: ${props => props.theme.breakMedium}) {
    padding: 1rem 1rem 0.7rem;

    span {
      display: block;
      text-align: center;
    }
  }
`;

const StyledFilename = styled.span`
  display: inline !important;
  margin-top: 0.5rem;
  width: auto;

  @media (max-width: ${props => props.theme.breakMedium}) {
    text-align: center;
  }
`;

const StyledQuestion = styled.h2`
  grid-area: question;
  ${props => props.theme.customTextStyle};
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: initial;
  text-align: initial;
`;

const StyledPrompter = styled.span`
  margin-top: 0.5rem;

  a {
    font-size: inherit;
    line-height: 0.9;
    border-bottom: 1px solid ${props => props.theme.black};
    color: ${props => props.theme.black};
  }

  @media (max-width: ${props => props.theme.breakMedium}) {
    text-align: initial;
  }
`;

const StyledContentField = styled.div`
  grid-area: content;
  display: grid;
  grid-template:
    'question question question'
    'file text url';
  gap: 1rem;

  @media (max-width: ${props => props.theme.breakMedium}) {
    display: block;

    ${StyledPrompter} {
      text-align: center;
    }
  }
`;

type StyledFieldProps = {
  $isCentered?: boolean;
};

const StyledField = styled.label<StyledFieldProps>`
  display: block;
  ${props =>
    props.$isCentered &&
    css`
      text-align: center;
    `};

  > * {
    display: inline-block;
    width: 100%;
  }

  input[type='date'] {
    -webkit-appearance: textfield;
    min-height: 2rem;
  }

  input {
    border: 2px solid ${props => props.theme.black};
    border-radius: 3px;
    padding: 0.5rem 0.8rem 0.3rem;
    font-size: 0.875rem;
    ${props => props.theme.customTextStyle};
  }

  span {
    ${props => props.theme.customTextStyle};
    font-size: 0.625rem;
  }

  @media (max-width: ${props => props.theme.breakMedium}) {
    margin-top: 1rem;
  }
`;

const StyledCheckboxField = styled(StyledField)`
  grid-area: terms;
  text-align: center;

  > * {
    width: initial;
  }

  input {
    vertical-align: middle;
    width: 1.2rem;
    height: 1.2rem;
    margin: 0 1rem 0 0;
    padding: 0;
    cursor: pointer;
    appearance: none;

    &:checked {
      background: ${props => props.theme.black};
    }
  }

  span {
    font-size: 0.75rem;
    letter-spacing: 0;
    text-transform: initial;
  }
`;

const StyledSubmit = styled.input`
  grid-area: submit;
  justify-self: center;
  border: 2px solid black;
  color: ${props => props.theme.white};
  font-size: 0.875rem;
  ${props => props.theme.customTextStyle};
  ${props => props.theme.borderMask(buttonUrl)};
  padding: 0.5rem 3rem 0.3rem;

  @media (max-width: ${props => props.theme.breakMedium}) {
    margin-top: 1rem;
  }
`;

const ContributeForm: React.FC = () => {
  useEffect(() => {
    const file = document.querySelector('#file');

    if (file) {
      file.addEventListener('change', e => {
        // Get the selected file
        const [file] = e.target.files;
        // Display filename
        document.querySelector('#file-name').textContent = file.name;
      });
    }
  }, []);

  return (
    <StyledContainer>
      <StyledBorder>
        <StyledForm name="contribute-form" method="POST" data-netlify="true">
          <input type="hidden" name="form-name" value="contribute-form" />
          <StyledContentField>
            <StyledQuestion>
              Share photos, videos, or audio from Courtney Barnett&apos;s shows
              with us here:
            </StyledQuestion>
            <StyledField $isCentered>
              <StyledUpload
                type="file"
                id="file"
                name="file"
                accept="image/*, video/*, audio/*"
              />
              <StyledUploadLabel htmlFor="file">
                Browse <span>[max 10mb]</span>
              </StyledUploadLabel>
              <StyledFilename id="file-name"></StyledFilename>
            </StyledField>
            <StyledUploadText>or share a link:</StyledUploadText>
            <StyledField>
              <input type="url" name="file-link" />
              <StyledPrompter>
                Dropbox/Box or other downloadable links preferred for files over
                10MB
              </StyledPrompter>
            </StyledField>
          </StyledContentField>
          <StyledField>
            <StyledQuestion>When did the show happen?</StyledQuestion>
            <input type="date" name="date" placeholder="dd/mm/yyyy" />
            <StyledPrompter>
              Feel free to skip to this if you can&apos;t remember the date.
            </StyledPrompter>
          </StyledField>
          <StyledField>
            <StyledQuestion>Where did the show happen?</StyledQuestion>
            <input type="text" name="venue" />
            <StyledPrompter>
              If you can remember the venue, or just the city and suburb.
            </StyledPrompter>
          </StyledField>
          <StyledField>
            <StyledQuestion>How would you like to be credited?</StyledQuestion>
            <input type="text" name="credit" />
            <StyledPrompter>
              You can stay anonymous if you&apos;d like!
            </StyledPrompter>
          </StyledField>
          <StyledField>
            <StyledQuestion>Your website/link to be credited:</StyledQuestion>
            <input type="url" name="credit-link" />
            <StyledPrompter>Optional</StyledPrompter>
          </StyledField>
          <StyledField>
            <StyledQuestion>Your email:</StyledQuestion>
            <input type="email" name="email" />
            <StyledPrompter>Optional</StyledPrompter>
          </StyledField>
          <StyledField>
            <StyledQuestion>Anything else?</StyledQuestion>
            <input type="text" name="others" />
          </StyledField>
          <StyledCheckboxField>
            <input type="checkbox" required />
            <StyledPrompter>
              Do you agree to these{' '}
              <a href="/terms" target="_blank" rel="noopener noreferrer">
                terms and conditions
              </a>
              ?
            </StyledPrompter>
          </StyledCheckboxField>
          <StyledSubmit type="submit" value="Submit" />
        </StyledForm>
      </StyledBorder>
      <StyledTape />
    </StyledContainer>
  );
};

export default ContributeForm;
