import React from 'react';
import { PageProps } from 'gatsby';

import Layout from '../layouts/Layout';
import SEO from '../components/SEO';

import useRandomBgColor from '../hooks/useRandomBgColor';
import ContributeForm from '../components/contribute/ContributeForm';

const ContributePage: React.FC<PageProps> = function (props) {
  const { location } = props;

  useRandomBgColor();

  return (
    <Layout currPage={location.pathname}>
      <SEO title="Contribute | Courtney Barnett" />
      <ContributeForm />
    </Layout>
  );
};

export default ContributePage;
